<template>
    <div
        :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
        class="main-menu menu-fixed menu-accordion menu-shadow"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >
        <!-- main menu header-->
        <div class="navbar-header expanded qf-logo">
            <slot
                :collapseTogglerIcon="collapseTogglerIcon"
                :toggleCollapsed="toggleCollapsed"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                name="header"
            >
                <ul class="nav navbar-nav flex-row">

                    <!-- Logo & Text -->
                    <li class="nav-item mr-auto">
                        <b-link
                            class="navbar-brand"
                            to="/"
                        >
                            <span class="brand-logo">
                                <vuexy-logo/>
                            </span>
                        </b-link>
                    </li>

                    <!-- Toggler Button -->
                    <li class="nav-item nav-toggle">
                        <b-link class="nav-link modern-nav-toggle">
                            <feather-icon
                                class="d-block d-xl-none"
                                icon="XIcon"
                                size="20"
                                @click="toggleVerticalMenuActive"
                            />
                            <feather-icon
                                :icon="collapseTogglerIconFeather"
                                class="d-none d-xl-block collapse-toggle-icon"
                                size="20"
                                @click="toggleCollapsed"
                            />
                        </b-link>
                    </li>
                </ul>
            </slot>
        </div>
        <!-- / main menu header-->

        <!-- Shadow -->
        <div
            v-if="false"
            :class="{'d-block': shallShadowBottom}"
            class="shadow-bottom"
        />

        <!-- main menu content-->

        <div class="qf-selector-label-holder">
            <p v-if="(showSwitch && isMouseHovered) || (showSwitch && !isVerticalMenuCollapsed)" class="font-weight-bolder text-center mb-1 text-white">
                Current production
            </p>
        </div>
        <div v-if="showSwitch" class="qf-production-switch cur-prod">
            <b-navbar-nav class="nav align-items-left ml-auto qf-navbar-production">
                <b-nav-item-dropdown
                    left
                    toggle-class="d-flex align-items-center dropdown-user-link qf-prod-dropdown"
                    class="dropdown-user"
                >
                    <template #button-content>
                        <div class="qf-production-selector">
                            <b-img
                                width="42"
                                height="56"
                                variant="light-primary"
                                :src="curProductionCover"
                                class="badge-minimal"
                            />
                            <div class="d-sm-flex d-none user-nav">
                                <span v-if="(showSwitch && isMouseHovered) || (showSwitch && !isVerticalMenuCollapsed)" class="ml-1 user-status qf-prod-label">{{curProductionName}}</span>
                            </div>
                        </div>
                    </template>

                    <b-dropdown-item
                        v-for="(oneProduction, index) in myProductions" :key="index"
                        @click="navToProd(oneProduction)"
                        link-class="d-flex align-items-center">
                        <b-img
                            width="38"
                            height="56"
                            variant="light-primary"
                            :src="prodCoverUrl(oneProduction)"
                            class="badge-minimal mr-05"
                        />
                        <div class="d-sm-flex user-nav">
                            <p class="mb-0 ">
                                {{oneProduction.attributes.name}}
                            </p>
                        </div>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </div>

        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
        >
            <vertical-nav-menu-items
                :items="filteredNavItems"
                class="navigation navigation-main"
            />
        </vue-perfect-scrollbar>
        <div id="app-ver">
            <span>ver {{ curVer }}</span>
        </div>
        <!-- /main menu content-->
    </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
    BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import {computed, provide, ref} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {$themeConfig} from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from "@/store";
import Parse from "parse";
import {RoleService} from "@/services/RoleService";


export default {
    components: {
        VuePerfectScrollbar,
        VerticalNavMenuItems,
        BImg,
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        VuexyLogo,
    },
    props: {
        isVerticalMenuActive: {
            type: Boolean,
            required: true,
        },
        toggleVerticalMenuActive: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            curUser: {
                attributes: {
                    firstName: '',
                    lastName: ''
                }
            },
            curVer: ''
        }
    },
    setup(props) {
        const {
            isMouseHovered,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            updateMouseHovered,
        } = useVerticalNavMenu(props)

        const {skin} = useAppConfig()

        // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
        const shallShadowBottom = ref(false)

        provide('isMouseHovered', isMouseHovered)

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'))

        // App Name
        const {appName, appLogoImage} = $themeConfig.app

        return {
            navMenuItems,
            perfectScrollbarSettings,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            isMouseHovered,
            updateMouseHovered,
            collapseTogglerIconFeather,

            // Shadow Bottom
            shallShadowBottom,

            // Skin
            skin,

            // App Name
            appName,
            appLogoImage,
        }
    },
    mounted() {
        this.curVer = `${process.env.VUE_APP_VERSION}`
        if (!Parse.User.current()) {
          this.logout();
        }else{
          this.curUser = Parse.User.current();
          this.getProductions(this.curUser.id);
        }
    },
    methods: {
        navToProd(oneProduction) {
            // this.activeProduction = oneProduction
            this.toggleVerticalMenuActive();
            store.commit('app/SET_PRODUCTION', oneProduction);
            this.$router.push(
                {path: '/production/'+this.activeProduction.id+'/overview/',
                 params: {prodId: this.activeProduction.id}
                }
            );
        },
        prodCoverUrl(production) {
            let productionImg = this.defaultLogo('svg');
            if (production.attributes.coverImage) {
                productionImg = production.attributes.coverImage._url;
            }
            return productionImg;
        },
        getProductions(curUserId){
            let self = this;
            let Production = Parse.Object.extend('Production');
            let prodQuery = new Parse.Query(Production);

            prodQuery.containedIn("teamMembers", [curUserId]);
            prodQuery.include('billingCompany');
            prodQuery.descending('createdAt');

            prodQuery.find()
                .then(function(result){
                    store.commit('app/SET_ALL_PRODUCTIONS', result);

                    if(self.myProductions.length>0){
                        self.myProductions.forEach(function(oneProd){
                            if(oneProd.id == self.$route.params.prodId){
                                store.commit('app/SET_PRODUCTION', oneProd);
                            }
                        });
                    }
                })
                .catch(function (err) {
                    console.log("Error: ", err.message);
                });
        },
        logout(){
            Parse.User.logOut();
            this.$router.push({ name:'login' }).catch();
        },
    },
    computed: {
        myProductions(){
          return store.getters['app/allProductions'];
        },
        activeProduction(){
          return store.getters['app/currentProduction'];
        },
        showSwitch(){
            let curRoute = this.$route.name;
            let prodRoutes = [
              'overview',
              'team',
              'roles',
              'roleDetails',
              'calendar'
            ]

            if(prodRoutes.indexOf(curRoute)>-1) {
                return true;
            }
            else {
                return false;
            }
        },
        curProduction() {
            return store.getters['app/currentProduction'];
        },
        filteredNavItems() {
            let navItems = [];
            if (this.$route.name != 'home' &&
                this.$route.name != 'my-profile'&&
                this.$route.name != 'prospects'
            ) {
                navItems = this.navMenuItems;
            }
            return navItems;
        },
        roleOptions(){
            let selectData = [];
            if(this.productions){
                this.productions.forEach(function(oneRole){
                    let oneLine = {
                        id: oneRole.id,
                        text:  oneRole.attributes.name,
                        fullData: oneRole.attributes,
                        suspended: oneRole.attributes.suspended
                    }
                    selectData.push(oneLine);
                });
            }
            return selectData;
        },
        roles(){
            return store.getters['app/allProductions'];
        },

        curProductionCover() {
            if(this.activeProduction) {
                return this.prodCoverUrl(this.activeProduction);
            }else{
                return this.defaultLogo('png');
            }
        },
        curProductionName() {
            if(this.activeProduction){
                return this.truncateString(this.activeProduction.attributes.name, 40, ' ...')
            }else {
                return '';
            }
        },
      curProdId(){
          return this.$route.params.prodId;
      }

    },
    watch: {
        curProduction: {
            immediate: true,
            handler() {
                RoleService.getProductionRoles(this.$route.params.prodId).then((res) => {
                  return res;
                }, (err) => {
                  console.error(err);
                })
            }
        },
        curProdId: {
          immediate: true,
          handler(newId) {
            if(newId){
              this.getProductions(this.curUser.id);
            }

          }
        }
    }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

#app-ver {
    position: absolute;
    bottom: 0;
    opacity: 0.75;
    font-size: 0.75em;
    width: 100%;
    padding-right: 5px;
    padding-bottom: 5px;
    text-align: right;
}

#app-ver span {
    color: white;
    position: relative;
}

.qf-production-switch ul.dropdown-menu.show {
    top: 25px;
    margin-top: 20px;
    //max-height: 335px;
    overflow-y: scroll;
    background: #0c0f15;
}
.qf-production-switch ul.dropdown-menu.show {
  height: calc(100vh - 146px);
  overflow-y: scroll;
  transform: translate3d(5px, 54px, 0) !important;
}

.qf-prod-label {
    width: 166px;
    height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;

}

.qf-selector-label-holder {
    height: 28px;
    display: block;
    width: 100%;
}

</style>
