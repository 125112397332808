<template>
    <div
        class="app-content content"
        :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
    >
        <div class="content-overlay"/>
        <!-- Repositioned Navbar -->
        <b-navbar v-if="false" class="header-navbar navbar navbar-shadow align-items-center mb-2">
            <slot name="navbar" :toggleVerticalMenuActive="toggleVerticalMenuActive">
                <app-navbar-vertical-layout/>
            </slot>
        </b-navbar>
        <!--/ Navbar -->
        <div
            class="content-wrapper"
            :class="contentWidth === 'boxed' ? 'container p-0' : null"
        >
            <slot name="breadcrumb">
                <app-breadcrumb/>
            </slot>
            <div class="content-body">
                <transition
                    :name="routerTransition"
                    mode="out-in"
                >
                    <slot/>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {BNavbar} from 'bootstrap-vue'
import useVerticalLayout from "../../layout-vertical/useVerticalLayout";

export default {
    components: {
        AppBreadcrumb,
        BNavbar
    },
    setup() {
        const {
            toggleVerticalMenuActive,
        } = useVerticalLayout(navbarType, footerType)
        const {routerTransition, contentWidth, navbarType, footerType} = useAppConfig()

        return {
            routerTransition,
            contentWidth,
            toggleVerticalMenuActive
        }
    },
}
</script>

<style>

</style>
