<template>
    <b-row
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        class="content-header"
    >

        <!-- Content Left -->
        <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
        >
            <b-row class="breadcrumbs-top">
                <b-col cols="6" sm="9" md="8" class="align-items-center d-flex">
                    <h2 class="content-header-title pr-1 mb-0">
                        {{ $route.meta.pageTitle }}
                    </h2>
                    <div class="breadcrumb-wrapper">
                        <b-breadcrumb>
                            <b-breadcrumb-item to="/">
                                <feather-icon
                                    icon="HomeIcon"
                                    size="16"
                                    class="align-text-top"
                                />
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                v-for="item in $route.meta.breadcrumb"
                                :key="item.text"
                                :active="item.active"
                                :to="item.to"
                            >
                                {{ item.text }}
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                v-if="curRole.id && curProd && $route.path == '/production/'+ curProd.id +'/roles/'+ curRole.id + '/details' "
                                :active = true
                            >
                                {{ this.curRole.attributes.name }}
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                v-if="curRole.id && curProd && $route.path == '/production/'+ curProd.id +'/roles/'+ curRole.id + '/browse-talents' "
                                :active = true
                            >
                              {{ this.curRole.attributes.name }}
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                v-if="curRole.id && curProd && $route.path == '/production/'+ curProd.id +'/roles/'+ curRole.id + '/auditions' "
                                :active = true
                            >
                              {{ this.curRole.attributes.name }}
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                v-if="curRole.id && curProd && $route.path == '/production/'+ curProd.id +'/roles/'+ curRole.id + '/live-auditions' "
                                :active = true
                            >
                              {{ this.curRole.attributes.name }}
                            </b-breadcrumb-item>

                        </b-breadcrumb>
                    </div>
                </b-col>
                <b-col
                    v-if="!isHomePage && activeCollaborators && activeCollaborators.length"
                    cols="6"
                    sm="3"
                    md="4"
                    class="justify-content-end align-items-center d-flex"
                    @click="$router.push({path: '/production/'+curProd.id+'/team/'})"
                >
                    <h2 class="pr-2 m-0 d-none d-lg-block">Collaborators </h2>
                    <b-avatar-group class="d-flex justify-content-end" size="32px">
                        <b-avatar v-for="(collabUser) in activeCollaborators" :key="collabUser.id"
                                  class="border-dark"
                                  :text="collabInitials(collabUser)"
                                  :src="collabAvatarUrl(collabUser)"
                                  :title="collabUser.attributes.firstName + ' '+collabUser.attributes.lastName"
                                  v-b-tooltip.hover
                                  variant="primary"></b-avatar>
                    </b-avatar-group>
                </b-col>
            </b-row>
        </b-col>

        <!-- Content Right -->
        <b-col
                v-if="false"
            class="content-header-right text-md-right d-md-block d-none mb-1"
            md="3"
            cols="12"
        >
            <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
            >

                <template v-if="false" #button-content>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                    >
                        <feather-icon icon="SettingsIcon" />sss
                    </b-button>
                </template>

                <b-dropdown-item :to="{ name: 'apps-todo' }">
                    <feather-icon
                        icon="CheckSquareIcon"
                        size="16"
                    />
                    <span class="align-middle ml-50">Todo</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{ name: 'apps-chat' }">
                    <feather-icon
                        icon="MessageSquareIcon"
                        size="16"
                    />
                    <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{ name: 'apps-email' }">
                    <feather-icon
                        icon="MailIcon"
                        size="16"
                    />
                    <span class="align-middle ml-50">Email</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{ name: 'apps-calendar' }">
                    <feather-icon
                        icon="CalendarIcon"
                        size="16"
                    />
                    <span class="align-middle ml-50">Calendar</span>
                </b-dropdown-item>
            </b-dropdown>
        </b-col>
    </b-row>
</template>

<script>
import {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    // BButton,
    BAvatarGroup,
    BAvatar,
    VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from "@/store";
import Parse from "parse";

export default {
    directives: {
        Ripple,
        'b-tooltip':VBTooltip
    },
    components: {
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BAvatarGroup,
        BAvatar,
        // BButton,
    },
    data() {
      return {
          activeCollaborators: [],
          curUser: {},
      }
    },
    methods: {
        collabInitials(user){
            if(user){
                return user.attributes.firstName.substring(0,1) + user.attributes.lastName.substring(0,1);
            }else{
                return "";
            }
        },
        collabAvatarUrl(user){
            let urlString = "";
            if (user.attributes.avatar) {
                urlString = user.attributes.avatar._url;
            }
            return urlString
        },
        getCurrentUser(){
            let currentUser = Parse.User.current();
            if(currentUser){
                this.curUser = currentUser;
                this.curUserId = currentUser.id;
            }
        },
        getCollaborators(newProduction){
            let currentUser = Parse.User.current();
            let self = this;
            if(newProduction && newProduction.attributes && newProduction.attributes.teamMembers){
                const userQuery = new Parse.Query(Parse.User);
                userQuery.containedIn("objectId", newProduction.attributes.teamMembers);
                userQuery.find().then(function(users){
                    self.activeCollaborators = users.filter(
                        function (obj) {
                            return obj.id !== currentUser.id;
                        }
                    );
                })
            }else{
                self.activeCollaborators = [];
            }
        }
    },
    computed: {
        isHomePage(){
            if( this.$route.name != 'home' &&
                this.$route.name != 'my-profile' &&
                this.$route.name != 'team'){
                return false;
            }
            return true;
        },
        curProd(){
            return store.getters['app/currentProduction'];
        },
        curRole(){
            return store.getters['app/currentRole'];
        }
    },
    watch:{
        curProd: {
            immediate: true,
            handler(newProd){
                this.getCollaborators(newProd);
            }
        }
    }
}
</script>
