export default [
    {
        title: 'Overview',
        route: 'overview',
        icon: 'BookOpenIcon',
    },
    {
        title: 'Roles',
        route: 'roles',
        icon: 'StarIcon',
    },
    {
        title: 'Collaborators',
        route: 'team',
        icon: 'UsersIcon',
    },
    {
        title: 'Calendar',
        route: 'calendar',
        icon: 'CalendarIcon',
    },
]
