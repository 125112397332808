<template>
    <div class="w-100">
        <b-alert
                v-if="!getBilllingCompany && !isAtHome"
                variant="danger"
                show
        >
            <div class="alert-body">
                <span><strong>You have not added a Billing Company to this production. Your access to some pages will be limited.</strong></span>
            </div>
        </b-alert>
        <div class="navbar-container d-flex content align-items-center">
        
        
            <!-- Nav Menu Toggler -->
            <ul class="nav navbar-nav d-xl-none mr-2">
                <li class="nav-item">
                    <b-link
                            class="nav-link"
                            @click="toggleVerticalMenuActive"
                    >
                        <feather-icon
                                icon="MenuIcon"
                                size="32"
                        />
                    </b-link>
                </li>
            </ul>

            <div class="qf-production-switch" v-if="isInRoles">
                <div id="role-picker">
                    <Select2
                        class="mb-1"
                        v-model="selectedRole"
                        @select="switchRole($event)"
                        :options="roleOptions"
                        :settings="{
                            placeholder: {id: null, text: 'Select a Role', isPlaceholder: true},
                            templateResult: rolePreview,
                            templateSelection: rolePreview,
                            escapeMarkup: function(stuff){return stuff}
                        }"
                    />
                </div>
            </div>
        
            <!-- Left Col -->
        
            <div v-if="false" lass="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" >
                <dark-Toggler class="d-none d-lg-block"/>
            </div>
        
            <b-navbar-nav class="nav align-items-center ml-auto qf-current-profile">
                <b-nav-item-dropdown
                        right
                        toggle-class="d-flex align-items-center dropdown-user-link"
                        class="dropdown-user"
                >
                    <template #button-content>
                        <div class="d-sm-flex d-none user-nav">
                            <p class="user-name font-weight-bolder mb-0">
                                {{ avatarNames }}
                            </p>
                            <span class="user-status">Admin</span>
                        </div>
                        <b-avatar
                                size="40"
                                variant="light-primary"
                                badge
                                :src="avatarUrl"
                                :text="avatarNames"
                                class="badge-minimal ml-1"
                                badge-variant="success"
                        />
                    </template>
                
                    <b-dropdown-item @click="gotoProfile" link-class="d-flex align-items-center">
                        <feather-icon
                                size="16"
                                icon="UserIcon"
                                class="mr-50"
                        />
                        <span>Profile</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="isAdmin" @click="gotoProspects" link-class="d-flex align-items-center">
                        <feather-icon
                            size="16"
                            icon="UsersIcon"
                            class="mr-50"
                        />
                        <span>Prospects</span>
                    </b-dropdown-item>
                
                    <b-dropdown-item v-if="false" link-class="d-flex align-items-center">
                        <feather-icon
                                size="16"
                                icon="MailIcon"
                                class="mr-50"
                        />
                        <span>Inbox</span>
                    </b-dropdown-item>

                    <b-dropdown-divider/>
                
                    <b-dropdown-item @click='logout' link-class="d-flex align-items-center">
                        <feather-icon
                                size="16"
                                icon="LogOutIcon"
                                class="mr-50"
                        />
                        <span>Logout</span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

        </div>
    </div>
    
</template>

<script>
import {
    BAlert,
    BAvatar,
    BDropdownDivider,
    BDropdownItem,
    //BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown
} from 'bootstrap-vue'
// import { isNavigationFailure, NavigationFailureType } from 'vue-router'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import Parse from 'parse';
import store from "@/store";
import Select2 from 'v-select2-component';
import Api2 from "@/services/api2";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
        //BImg,
        BAlert,
        Select2
        //Navbar Components
        //DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {
            },
        },
    },
    data() {
        return {
            curUser: {
                attributes: {
                    firstName: '',
                    lastName: ''
                }
            },
            userAvatar: '',
            myProductions: [],
            prodRoles: [],
            activeProduction: {
                id: null,
                attributes: {
                    name: ''
                }
            },
            selectedRole: null,
            activeRole: {
                id: null,
                attributes: {
                    name: ''
                }
            },
        }
    },
    mounted() {
        if (!Parse.User.current()) {
          this.logout();
        }else{
          this.curUser = Parse.User.current();
          this.getProductions(this.curUser.id);
          const userObj = {
            sessionToken: this.curUser.attributes.sessionToken
          }
          Api2.sendData('getMyRoles', userObj).then((res) => {
            if (res.data.roles && res.data.roles.indexOf('PlatformOwner') > -1) {
              store.commit('app/SET_IS_ADMIN', true);
            }
          });
        }

    },
    computed: {
        isAdmin(){
          return store.getters['app/isAdmin'];
        },
        roleOptions(){
            let selectData = [];
            let dayPlayers = [];
            if(this.roles){
                this.roles.forEach(function(oneProd){
                    let oneLine = {
                        id: oneProd.id,
                        text:  oneProd.attributes.name,
                        fullData: oneProd.attributes,
                        suspended: oneProd.attributes.suspended
                    }
                      if(oneProd.attributes.roleType == 'dayPlayer'){
                        dayPlayers.push(oneLine);
                    } else {
                        selectData.push(oneLine);
                    }

                });
            }

            if(dayPlayers.length > 0){
                selectData.concat(dayPlayers);
            }
            //debugger;
            return selectData;
        },
        roles(){
            return store.getters['app/allRoles'];
        },
        getBilllingCompany(){

            if(!this.activeProduction ||
                (this.activeProduction && this.activeProduction.attributes.billingCompany !== undefined)){
                return true;
            }
            else{
                return false;
            }
        },
        isInRoles(){
            let disabledList = [
                'home',
                'roles',
                'my-profile',
                'prospects'
            ]
            return disabledList.indexOf(this.$route.name) === -1;
        },
        avatarUrl() {
            let avatarUrl = this.defaultLogo('png');

            if (this.curUser.attributes.avatar) {
                avatarUrl = this.curUser.attributes.avatar._url;
            }

            return avatarUrl;
        },
        avatarNames() {
            return this.curUser.attributes.firstName + ' ' + this.curUser.attributes.lastName;
        },
        isAtHome(){
            if(this.$route.name == 'home'){
                return true;
            }else{
                return false;
            }
        },
        curProduction() {
            return store.getters['app/currentProduction'];
        },
        curRole() {
            return store.getters['app/currentRole'];
        },

        curProductionCover() {
            if(this.activeProduction) {
                return this.prodCoverUrl(this.activeProduction);
            }else{
                return this.defaultLogo('png');
            }
        },
        curProductionName() {
            if(this.activeProduction){
                return this.truncateString(this.activeProduction.attributes.name, 40, ' ...')
            }else {
                return '';
            }
        },

    },
    methods: {
        switchRole(roleSelection){

            if(roleSelection.id){
                let role = this.roles.find(function(oneRole){
                    return oneRole.id == roleSelection.id;
                });
                if(role) {
                    store.commit('app/SET_ROLE', role);
                    this.activeRole = role;
                }
                let tab = '/details';
                if(this.$route.params.tab){
                  tab = '/' + this.$route.params.tab;
                }
                //if(!this.$route.params.roleId || (this.$route.params.roleId && roleSelection.id !== this.$route.params.roleId)) {
                    this.$router.push({path: '/production/'+this.curProduction.id+'/roles/'+roleSelection.id + tab});
                //}

            }
        },
        roleTypeLabel(type) {
            let labels = {
                lead: "Lead",
                supporting: "Supporting",
                day_player: "Day Player",
            };

            if (labels[type]) {
                return "(" + labels[type] + ")";
            } else {
                return "";
            }
        },
        rolePreview(choice){
            let markup = '';
            if(choice && choice.fullData){
                let item = choice.fullData;

                let charIllustrationSrc = this.defaultLogo('svg-white');

                if(item.characterIllustration){
                    charIllustrationSrc = item.characterIllustration._url;
                }

                if(item.suspended){
                    markup = `<div class="qf-production-selector">`;
                }else {
                    markup = `<div class="qf-production-selector active">`;
                }
                markup += `<div class="qf-select2-preview-container">`;
                markup += `<img variant="light-primary" class="qf-select2-preview badge-minimal w-100" src="`+charIllustrationSrc+`"/>`;
                markup += `</div>`;
                markup += `<div class="qf-select2-text-label">`;
                markup +=   `<p class="mb-0">`+item.name+`</span>`
                markup +=   `<span class="qf-select2-subtitle">`+this.roleTypeLabel(item.roleType)+`</span>`;
                markup +=`</div>`;

                markup +=`</div>`;

                // markup += `<span class="profile-avatar">`;
                // markup += `<img class="comp-logo" src="`+item.coverImage._url+`" />`;
                // markup += `</span>`;
                // markup += `<h3>`+item.name+`</h3>`;

            }else if(choice.isPlaceholder){
                markup = `<div class="qf-production-selector">`;
                markup +=   `<div class="qf-select2-text-label qf-select2-placeholder">`;
                markup +=       `<p class="mb-0">`+choice.text+`</span>`
                markup +=   `</div>`;
                markup +=`</div>`;
            }
            return markup;
        },
        navToProd(prodObj){
            store.commit('app/SET_PRODUCTION', prodObj);
            this.activeProduction = prodObj;
            this.$router.push({path: '/production/'+prodObj.id+'/overview'})
        },
        getProductions(curUserId){
            let self = this;
            let Production = Parse.Object.extend('Production');
            let prodQuery = new Parse.Query(Production);

            prodQuery.containedIn("teamMembers", [curUserId]);
            prodQuery.include('billingCompany');

            prodQuery.find()
                .then(function(result){
                    self.myProductions = result;
                })
                .catch(function (err) {
                    console.log("Error: ", err.message);
                });
        },

        prodCoverUrl(production) {
            let productionImg = this.defaultLogo('svg');
            if (production.attributes.coverImage) {
                productionImg = production.attributes.coverImage._url;
            }
            return productionImg;
        },
        characterIllustration(oneRole){
            if(oneRole.attributes.characterIllustration){
                return oneRole.attributes.characterIllustration._url;
            }else{
                return this.defaultLogo('svg');
            }
        },
        gotoProfile(){
            this.$router.push({name: 'my-profile'})
        },
        gotoProspects(){
            this.$router.push({name: 'prospects'})
        },
        logout(){
            const self = this;
            Parse.User.logOut();
            this.$router.push({ name:'login' }).catch(() => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Login in order to access the admin panel!",
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            });
        },

    },
    watch: {
        curProduction: {
            immediate: true,
            handler(newVal){
                this.activeProduction = newVal;
            }
        },
        roles: {
            immediate: true,
            deep: true,
            handler(newRoleSet){
                if(newRoleSet.length > 0){
                    this.activeRole = newRoleSet[0];
                }
            }
        },
        curRole: {
            immediate: true,
            handler(newVal){
                this.activeRole = newVal;
                this.selectedRole = newVal.id;
            }
        },
    }
}
</script>

<style>

    #role-picker {
        min-width: 300px;
    }
    /*
         .alert .alert-body {
             color: red;
            padding: 0.71rem 1rem;
            background-color: #ff001926;
            display: block;
         }
        .qf-production-selector {
            display: flex;
        }
        .qf-production-selector .user-nav{
            display: flex;
            flex-direction: column;
        }


        .qf-navbar-production .dropdown-menu .dropdown-item {
          white-space: initial;
        }
        .qf-production-switch{
            padding: 0.8rem 0;
            background: #283046;
            border-radius: 5px;
        }

     */
</style>
